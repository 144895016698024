//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';

import LocationIcon from '~/static/svg/location-blue.svg';
import TradeIcon from '~/static/svg/trade-blue.svg';
import SearchIcon from '~/static/svg/search.svg';
import CartIcon from '~/static/svg/cart.svg';
import UserIcon from '~/static/svg/user-blue.svg';
import AddToQuotesIcon from '~/static/svg/add-to-quotes.svg';

export default {
  components: {
    LocationIcon,
    TradeIcon,
    SearchIcon,
    CartIcon,
    UserIcon,
    AddToQuotesIcon,
  },
  props: {
    dontShowChinbarAccounts: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      activeClass: '',
      showMyAccount: false,
      showSearchForm: false,
      showLoginPopup: false,
      shouldShowChinBar: false,
      queryModel: '',
      count: 0,
      popupPage: false,
    };
  },
  computed: {
    ...mapGetters({
      getIsSearchBarVisible: 'layout/getIsSearchBarVisible',
      getIsMyAccountVisible: 'layout/getIsMyAccountVisible',
      getItemsCount: 'cart/getItemsCount',
      getIsLoggedIn: 'users/getIsLoggedIn',
      getTotalJobLists: 'joblist/getTotalJobLists',
    }),
    async isSearchBarVisible() {
      const isVis = await this.getIsSearchBarVisible();
      return isVis;
    },
    async isMyAccountVisible() {
      const isVis = await this.getIsMyAccountVisible();
      return isVis;
    },
    getCount() {
      const count = this.getItemsCount();

      if (count > 9) {
        return '9+';
      }

      return count;
    },
    featureBtnText() {
      if (this.$config.featureToggle.toggles.tradePortalMVP) {
        return this.getIsLoggedIn
          ? this.$t('general.header.my_account')
          : this.$t('general.header.login');
      } else {
        return this.$t('general.header.trade_portal');
      }
    },
    canUseJobListFeatures() {
      return this.$config.featureToggle.toggles.enableJobListFeature;
    },
  },
  methods: {
    resetActiveClass() {
      this.activeClass = '';
    },
    resetShowSearchForm() {
      this.showSearchForm = false;
    },
    resetQueryModel() {
      this.queryModel = '';
    },
    triggerModal(classname) {
      this.activeClass = this.activeClass === classname ? '' : classname;

      if (this.getIsLoggedIn) {
        this.$router.push({ path: '/joblist/' });
        this.showLoginPopup = false;
        return;
      }

      this.togglePopup(true);
    },
    handleClick(classname) {
      // Hide popup if clicking outside login or list
      if (classname !== 'feature-btn' && classname !== 'joblist') {
        this.closePopup();
      }
      this.activeClass = this.activeClass === classname ? '' : classname;
      if (classname === 'search') {
        let wrap = document.getElementById('header_autocomplete');
        let inputHtml = wrap.getElementsByClassName(
          'aa-DetachedSearchButton'
        )[0];
        if (inputHtml) inputHtml.click();
      }

      if (classname === 'feature-btn') {
        if (this.$featureToggle.toggles.tradePortalMVP) {
          this.activeClass =
            this.activeClass === 'feature-btn' ? '' : 'feature-btn';
          this.togglePopup(false);
        } else {
          window.open(this.$t('links.page.trade_portal'), '_blank');
        }
      }
    },
    togglePopup(isJobList) {
      if (this.showLoginPopup && this.popupPage !== isJobList) {
        this.closePopup();
        setTimeout(() => {
          this.popupPage = isJobList;
          this.showLoginPopup = true;
        }, 300);
      } else {
        this.popupPage = isJobList;
        this.showLoginPopup = !this.showLoginPopup;
      }
    },
    closePopup() {
      this.showLoginPopup = false;
    },
    handleWindowResize() {
      this.shouldShowChinBar = window && window.innerWidth <= 1180;
    },
    submitSearch() {
      let path = '/search/';

      const query = {
        query: this.queryModel,
      };

      this.$router.push({
        path,
        query,
      });

      this.resetQueryModel();
      this.resetActiveClass();
      this.resetShowSearchForm();
    },
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleWindowResize);
  },
  beforeMount() {
    window.addEventListener('resize', this.handleWindowResize);
  },
  mounted() {
    this.handleWindowResize();
  },
};
